// fonts
$font-family-opensans: 'Open Sans', sans-serif;
$font-family-barlow-regular: 'Barlow', sans-serif;
$font-family-barlow: 'Barlow Condensed', sans-serif;
$font-family-semi-barlow: 'Barlow Semi Condensed', sans-serif;

// colours
$transparent: transparent;
$dark-gray: #999999;
$secondary: #ed2e30;
$primary: #15153b;
$white: #ffffff;
$light: #f1f1f1;
$gray: #dddddd;
$green: rgb(29, 133, 3);

$colours:
"dark-gray" $dark-gray,
"secondary" $secondary,
  "primary" $primary,
  "light" $light,
  "white" $white,
  "green" $green,
  "gray" $gray;

$fz-extra-small: 14px;
$fz-x-small: 16px;
$fz-small: 18px;
$fz-medium: 22px;
$fz-xmedium: 29px;
$fz-large: 36px;
$fz-semi-large: 52px;
$fz-extra-large: 72px;

$font-sizes:
  "fz-extra-small" $fz-extra-small,
  "fz-medium" $fz-medium,
  "fz-x-small" $fz-x-small,
  "fz-small" $fz-small,
  "fz-xmedium" $fz-xmedium,
  "fz-large" $fz-large,
  "fz-semi-large" $fz-semi-large,
  "fz-extra-large" $fz-extra-large;