@mixin append-attr($x) {
    $sel: &;
    $collector: ();

    @for $i from 1 through length($sel) {
        $s: nth($sel, $i);
        $last: nth($s, -1);
        @if str-slice($last, -1) == "]" {
            // if is just the bare attribute with no value, $offset will be -1, otherwise it will be -2
            $offset: -1;
            $current-x: $x;

            @if str-slice($last, -2) == '"]' {
                // this attribute already has a value, so we need to adjust the offset
                $offset: -2;
            } @else {
                // no attribute value, so add the equals and quotes
                $current-x: '="' + $x + '"';
            }
            $last: str-slice($last, 1, $offset - 1) + $current-x + str-slice($last, $offset);
            $collector: append($collector, set-nth($s, -1, $last), comma);
        } @else {
            // following line will append $x to your non-attribute selector
            $collector: append($collector, selector-append($s, $x), comma);
            // the following line will not change your non-attribute selector at all
            //$collector: append($collector, $s, comma);
        }
    }

    @at-root #{$collector} {
        @content;
    }
}