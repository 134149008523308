.modal-newsletter-preferences {
  position: fixed;
  z-index: 9999;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  max-width: 1000px;
  width: 100%;

  &.active {
    display: flex;
    flex-wrap: wrap;
  }

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: #ebebeb;

    p {
      font-size: 18px;
      font-weight: bold;
      color: #3f4059;
    }

    .box-close {
      padding-right: 30px;

      .close {
        width: 25px;
        height: 25px;
        cursor: pointer;
        background: url(../../portal/imagens/btn-close-cookies-preferences.png) no-repeat center center;
        display: block;
      }
    }
  }

  .content {
    display: flex;
    width: 100%;
    min-height: 340px;

    .tabs-nav {
      width: 40%;

      ul {
        li {
          display: flex;
          color: #3f4059;
          font-size: 14px;
          border-bottom: 1px solid #e5e5e5;

          .box-text {
            cursor: pointer;
            font-weight: bold;
            width: 244px;
            line-height: 1.2;
            padding: 12px 20px;

            &.active {
              background-color: #00afef;
              color: #ffffff;
            }
          }

          .box-accepts {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 144px;
            padding: 0 10px;

            &.necessary-cookies {
              justify-content: flex-end;
              font-weight: bold;
              color: #00afef;
            }

            .accept {
              input {
                appearance: auto;
              }
            }
          }

          &:last-child {
            border: none;
            justify-content: center;
            flex-direction: column;
            padding: 5px 30px 30px 30px;

            .message {
              height: 25px;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;

              &.show {
                color: #ff3a3a;
                font-weight: bold;
              }
            }

            .box-buttons {
              display: flex;
              flex-direction: row;

              button {
                border: none;
                width: 170px;
                font-weight: bold;
                font-size: 14px;
                padding: 10px 0;
                border-radius: 10px;
                cursor: pointer;

                &:first-child {
                  margin-right: 4px;
                }

                &:last-child {
                  margin-left: 4px;
                }
              }
            }
          }
        }
      }
    }

    .tabs {
      width: 60%;
      padding: 25px 40px;

      .tab {
        display: none;
        font-size: 14px;
        color: #666666;

        &.active {
          display: block;
        }

        h3 {
          all: unset;
        }

        h3 {
          margin-bottom: 20px;
          color: #42435b;
          font-weight: bold;
          display: block;
        }

        p {
          font-size: 14px;
          margin-bottom: 20px;
          line-height: 1.3;
          text-align: justify;
        }
      }
    }
  }

  .footer {
    all: unset;
  }

  .footer {
    border-top: 1px solid #e5e5e5;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 20px 0;

    button {
      border: none;
      height: 40px;
      border-radius: 10px;
      background-color: #00afef;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      width: 220px;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 768px) {
    transform: none;
    top: 0;
    left: 0;
    height: 100vh;
    overflow: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .header {
      padding: 15px;

      img {
        display: none;
      }

      .box-close {
        padding: 0;
      }
    }

    .content {
      height: 100%;
      min-height: unset;
      flex-direction: column;

      .tabs-nav {
        width: 100%;

        ul {
          li {
            .box-text {
              width: 100%;
            }

            .box-accepts {
              width: 200px;
            }

            &:last-child {
              padding: 5px 20px 20px 20px;

              .box-buttons {
                justify-content: center;
              }
            }
          }
        }
      }

      .tabs {
        width: 100%;
        padding: 0 20px;
      }
    }
  }

  @media only screen and (max-width: 425px) {
    .header {
      p {
        font-size: 15px;
        line-height: 1.2;
      }
    }

    .content {
      .tabs-nav {

        ul {
          li {
            &:last-child {
              display: flex;
              flex-direction: column;

              .box-buttons {
                // flex-direction: column;

                button {
                  width: 100%;
                  margin-bottom: 10px;

                  &:first-child {
                    margin-right: 3px;
                  }

                  &:last-child {
                    margin-left: 3px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .footer {
      padding: 20px;

      button {
        width: 100%;
      }
    }
  }
}