//@import "bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap&family=Barlow+Condensed:wght@400;500;700;800;900&family=Barlow+Semi+Condensed:wght@900&family=Open+Sans:wght@400;700&display=swap');

// Required
@import "variables";
@import "mixins";

@import "~bootstrap/scss/bootstrap";
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";
// @import "~bootstrap/scss/transitions";
// @import "~bootstrap/scss/pagination";
// Optional
// @import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/type";
// @import "~bootstrap/scss/images";
// @import "~bootstrap/scss/code";
// @import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/forms";
// @import "~bootstrap/scss/utilities";
// @import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/close";
@import "~select2/dist/css/select2.css";
@import "modal-newsletter";

body {
    font-family: $font-family-opensans;
}

.font-family-barlow-regular {
    font-family: $font-family-barlow-regular;
}
.font-family-barlow {
    font-family: $font-family-barlow;
}
.font-family-semi-barlow {
    font-family: $font-family-semi-barlow;
}
.font-family-opensans {
    font-family: $font-family-opensans;
}

.rounded-semi-pill {
    border-radius: 30px;
}

.font-weight-900 {
    font-weight: 900 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.list-style-none {
    list-style: none;
}

.border-bottom-secondary {
    border-bottom: 5px solid $secondary;
}

.mw-50 {
    max-width: 50%;
}

@each $sizes, $value in $font-sizes {
    .#{$sizes} {
        font-size: #{$value};
    }
}

.line-height-100 {
    line-height: 100%;
}
.line-height-120 {
    line-height: 120%;
}

.home-banner {
    .slide {
        background: no-repeat center right;
        background-size: cover;
        .container {
            min-height: 450px;
            position: relative;
            z-index: 4;
        }
    }
    .col-lg-5 {
        position: relative;
        z-index: 2;
    }
    .banner-background {
        position: absolute;
        top: 0;
        height: 100%;
        right: 0;
        z-index: 1;
        width: auto;

        img {
            opacity: 0.5;
            height: 100%;
            display: block;
            margin: 0;
            margin-left: auto;
            margin-right: 20px;
        }

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 60%;
            height: 100%;
            z-index: 3;
            content: '';
            background: $primary;
            background: -moz-linear-gradient(90deg, $primary 0, rgba($primary,0) 100%);
            background: -webkit-linear-gradient(90deg, $primary 0, rgba($primary,0) 100%);
            background: linear-gradient(90deg, $primary 0, rgba($primary,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$primary,endColorstr=$primary,GradientType=1);
        }

        &:after {
            position: absolute;
            top: 0;
            right: 20px;
            width: 20%;
            height: 100%;
            z-index: 3;
            content: '';
            background: $primary;
            background: -moz-linear-gradient(90deg, rgba($primary,0) 0%, $primary 100%);
            background: -webkit-linear-gradient(90deg, rgba($primary,0) 0%, $primary 100%);
            background: linear-gradient(90deg, rgba($primary,0) 0%, $primary 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$primary,endColorstr=$primary,GradientType=1);
        }
    }
}

header {
    .bi-chevron-down.text-secondary:before {
        content: '';
        width: 20px;
        height: 20px;
        background: 50% / 100% 100% no-repeat;
        transform: rotate(-90deg);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ed2e30' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
    }

    .menu-topo-fixado {
        .bi-chevron-down.text-secondary:before {
            width: 15px;
            height: 15px;
        }
    }
}

@media screen and (max-width: 648px) {
    .fz-extra-large {
        font-size: 52px !important;       
    }
}

$transition-duration: 0.3s;
$transition-delay: 0.17s;

.animated {
    .fadeUp {
        opacity: 0;
        transform: translate(0, 20px);
        transition-duration: $transition-duration;
        transition-property: opacity, transform;
        transition-timing-function: ease;
    }
    
    [data-delay] {
        animation-delay: 0s !important;
        
        @include append-attr("1") {
            animation-delay: $transition-delay * 1 !important;
        }
        
        @include append-attr("2") {
            animation-delay: $transition-delay * 2 !important;
        }
        
        @include append-attr("3") {
            animation-delay: $transition-delay * 3 !important;
        }

        @include append-attr("4") {
            animation-delay: $transition-delay * 4 !important;
        }
    }

    .active {
        .fadeUp {
            animation: fadeUp $transition-duration forwards;
        }
    }
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translate(0, 20px);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

.logo {
    width: 420px;
    max-width: 100%;
}

.border-left-secondary {
    border-left: solid 10px $secondary;
}

.bordered-title {
    position: relative;

    &:after {
        height: 1px;
        position: absolute;
        top: 50%;
        left: 0;
        background: $white;
        content: '';
        width: 100%;
        z-index: 2;
    }

    span {
        position: relative;
        z-index: 3;
    }

    &.text-primary {
        &:after {
            background: $primary;
        }
    }
}

.midias {
    a {
        transition: all ease $transition-duration;
    }
    &:hover {
        a {
            opacity: 0.5;
        }
    }
    a {
        &:hover {
            opacity: 1;
        }
    }
}

.product_ref .select2.select2-container.select2-container--default {
    width: 100% !important;
}

.newsletter {
    .select2-container .select2-selection {
        border: 0 !important;
        height: 35px !important;
        padding-bottom: 0.25rem !important;
        padding-top: 0.25rem !important;
    }
}

.select2-container {
    font-size: 18px;
    .select2-selection {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        padding-bottom: 0.5rem !important;
        padding-top: 0.5rem !important;
        background: #ffffff !important;
        height: 45px !important;
        border-color: rgb(118, 118, 118);
        border-radius: 50rem !important;
    }
}

.select2-dropdown {
    z-index: 1 !important;
    border: 0 !important;
    font-size: 18px !important;
    font-family: $font-family-barlow !important;

    // &.select2-dropdown--below {
    //     padding-top: 20px;
    //     transform: translate(0, -20px);
    // }
    
    // &.select2-dropdown--above {
    //     padding-bottom: 20px;
    //     transform: translate(0, 20px);
        
    // }
}

.select2-container .select2-selection {
    position: relative !important;
    // z-index: 99;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $primary !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 40px !important;
    top: 0 !important;
    b {
        position: relative !important;
        top: auto !important;
        left: auto !important;
        border: 0 !important;
        margin: 0 !important;
        content: '' !important;
        width: 15px !important;
        height: 15px !important;
        background: 50% / 100% 100% no-repeat !important;
        transform: rotate(-90deg) !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2315153b' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;
    }
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, 
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-radius: 1.5rem !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, 
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
    border-radius: 1.5rem !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

@each $color, $value in $colours {
    .bg-#{$color} {
        background: #{$value};
    }
    .text-#{$color} {
        color: #{$value};
    }
}

@media only screen and (max-width: 992px) {
    .menu-primary, .menu-white {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        background: $primary;
        z-index: 9;

        &.show {
            display: block !important;
        }
    }

    .menu-primary {
        top: 100%;
    }

    .menu-white {
        background: $white;
    }
}
@media only screen and (min-width: 992px) {
    .search {
        width: auto;
        flex-basis: auto;
        flex-grow: initial;
    }
}

.solucoes {
    .solution-item {
        width: 100%;
        padding-top: 68%;
        display: block;
        background: center no-repeat;
        background-size: 110%;
        transition: background ease $transition-duration;

        // &:hover {
        //     background-size: 120%;
        // }
    }
}

.product-item {
    img, .bg-secondary {
        transition: all ease $transition-duration;
    }
    &:hover {
        img {
            transform: scale(1.15);
        }
        .bg-secondary {
            background-color: $primary !important;
        }
    }
}

.choices-home {
    hr {
        width: 1px;
        height: 180px;
        background: #dee2e6;
        align-self: center;
        display: none;

        @media only screen and (min-width: 992px) {
            display: block;
        }

        &:last-of-type {
            display: none !important;
        }
    }
}

.hover-submenu:hover {
    .submenu-container {
        display: block;
    }
}

.submenu-container {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9;
    display: none;

    .bg-white & {
        background: $white;
    }

    &.show {
        display: block;
    }

    &.menu-clube {
        left: auto;
        right: 0;
    }

    &.menu-produtos {
        .line-height-120 {
            border-top: 3px solid $red;
            padding-top: 10px;
        }
    }
}

.post-detalhe {
    img {
        max-width: 100%;
        display: block;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
    .row {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.home-about {
    img {
        transition: all ease $transition-duration;
    }
    a {
        overflow: hidden;
        display: block;

        &:hover {
            img {
                transform: scale(1.15);
            }
        }
    }
}

.trigger-menu-mobile {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;

    &:after,
    &:before, span {
        display: block;
        content: '';
        position: absolute;
        height: 4px;
        background: #fff;
        border-radius: 50px;
        top: 0;
        left: 0;
        width: 100%;
    }

    span {
        top: 13px;
    }

    &:before {
        top: 26px;
    }

    &.active {
        span {
            display: none;
        }

        &:before {
            transform: rotate(45deg);
            top: 15px;
        }
        
        &:after {
            top: 15px;
            transform: rotate(-45deg);
        }
    }
}

.mobile-menu {
    .position-fixed {
        top: 0;
        left: 0;
        z-index: 9999;
    }

    .submenu-container {
        position: relative;
        top: auto;
        left: auto;
        &.show {
            display: block;
        }
    }
}

.mapa {
    .bi-chevron-down:before {
        content: '';
        width: 20px;
        height: 20px;
        background: 50% / 100% 100% no-repeat;
        transform: rotate(-90deg);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2315153b' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
    }
    .bi-chevron-down:before {
        width: 15px;
        height: 15px;
    }
    .submenu-container {
        position: relative;
        top: auto;
        left: auto;
        &.show {
            display: block;
        }
    }
}

.menu-topo-fixado {
    transition: top 0.5s ease;
    top: -100%;
    z-index: 9999;
}

.product-title {
    h2, p {
        line-height: 100%;
    }
    h2 {
        font-weight: 900;
    }
}

.product-table {
    td {
        border-color: $white !important;
    }
}

.product-list {
    position: relative;
    display: flex !important;

    a {
        position: relative;
    }

    hr {
        width: 40%;
        margin: 15px auto;
    }

    .bg-secondary.rounded-pill {
        position: absolute;
        bottom: -14px;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

.breadcrumb-item + .breadcrumb-item::before {
    content: '>';
    font-family: cursive;
}

.slick-arrow {
    position: absolute;
    top: 0;
    width: 40px;
    height: 100%;
    left: 0;
    z-index: 2;
    border: 0;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8'%3E%3Cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E") no-repeat center center;
    text-indent: -9999px;
    background-size: 20px;
    overflow: hidden;
    &.slick-prev {
        transform: rotate(-180deg);
    }
    &.slick-next {
        right: 0;
        left: auto;
    }
}

.slick-dots {
    li {
        // width: 100%;
        height: auto;
        list-style: none;
    }

    @media only screen and (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px 0 10px;
        flex-flow: row !important;

        button {
            width: 10px;
            height: 10px;
            border: 0;
            border-radius: 50%;
            background: $primary;
            margin: 0 5px;
            padding: 0;
            border: 0;
            text-indent: -9999px;

            img {
                display: none !important;
            }

            &.slick-active, &:hover {
                background: $secondary;
            }
        }
    }
}

.slick-vertical {
    padding: 30px 0;
    .slick-arrow {
        height: 30px;
        overflow: hidden;
        width: 100%;
        background: #f3f3f3;
        left: 0;

        &:before {
            content: '';
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8'%3E%3Cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E") no-repeat center center;
            transform: translate(-50%, 0) rotate(90deg);
            width: 30px;
            height: 30px;
            position: absolute;
            background-size: 20px;
            left: 50%;
            opacity: 0.35;
            top: 0;
        }

        &.slick-prev {
            &:before {
                transform: translate(-50%, 0) rotate(90deg);
            }
        }
        &.slick-next {
            bottom: 0;
            top: auto;
        }
        &.slick-disabled {
            opacity: 0.5;
        }
    }
}

.carousel  {
    overflow: hidden;
    .home-banner & {
        .carousel-item {
            position: relative;
            background: no-repeat center center;

            @media only screen and (max-width: 992px) {
                background-position: center right;
            }

            .container {
                position: relative;
                z-index: 4;
            }
        }
    }
    .carousel-indicators {
        li {
            width: 100%;
            height: auto;
        }

        @media only screen and (max-width: 768px) {
            display: flex;
            padding: 25px 0 10px;
            flex-flow: row !important;
    
            li {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $primary;
                margin: 0 5px;
                padding: 0;
                border: 0;

                img {
                    display: none !important;
                }

                &.active, &:hover {
                    background: $secondary;
                }
            }
        }
    }

    .carousel-control-prev, .carousel-control-next {
        z-index: 5;
    }
}

// input checkboxes
// selects

input, select, textarea {
    border-width: 1px;
    border-style: solid;
    outline: none;

    &:focus {
        box-shadow: 0 0 0 1pt grey;
        transition: .1s;
    }
}

.error {
    label {
        color: $secondary !important;
    }

    input, select, textarea, .select2-selection  {
        color: $secondary !important;
        border-color: $secondary !important;
    }
}

input[type="checkbox"] {
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    position: fixed;
    top: -10px;
    left: -10px;

    & + label {
        padding-left: 20px;
        min-height: 20px;
        position: relative;
    
        &:before {
            width: 15px;
            height: 15px;
            content: '';
            position: absolute;
            top: 6px;
            left: 0;
            border: 1px solid $dark-gray;
        }

        &.fz-extra-small {
            &:before {
                top: 3px;
            }
        }
    }

    &:checked + label {
        &:after {
            width: 9px;
            height: 9px;
            content: '';
            top: 9px;
            left: 3px;
            position: absolute;
            background: $secondary;
        }

        &.fz-extra-small {
            &:after {
                top: 6px;
            }
        }
    }
}

.categoria-list {
    .col-6 {
        .line-height-120 {
            border-top: 3px solid #dc3545;
            padding-top: 10px;
            min-height: 65px;
        }
    }

    a {
        transition: background-color ease $transition-duration;

        .bg-light {
            transition: background-color ease $transition-duration;
        }
        
        .line-height-120 {
            transition: color ease $transition-duration;
        }

        &:hover {
            background-color: $primary !important;
            .bg-light {
                background-color: $primary !important;
            }
            .line-height-120 {
                color: $white !important;
            }
        }
    }
}

.historic-line {
    .row {
        .bg-white {
            position: relative;

            &:before {
                content: "";
                display: block;
                position: absolute;
                width: 25px;
                height: 25px;
                background: $primary;
                left: -13px;
                top: 28px;
                border-radius: 50%;
                box-shadow: 0px 0px 0px 2px $dark-gray;
                border: 5px solid $white;
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                width: 2px;
                background: $dark-gray;
                height: 100%;
                top: 54px;
                left: -2px;
            }
        }

        &.end {
            .bg-white {
                &:before {
                    content: none;
                }
            }
        }
    }
}

.pagination {
    .page-item {
        &.first, &.last {
            .page-link {
                border-radius: 50px;

                &:hover {
                    background: $primary;
                    color: $secondary;
                }
            }
        }
        
        &:nth-child(2) .page-link {
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
        }
        
        &:nth-last-child(2) .page-link {
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
        }


        .page-link {
            border: 0;
        }

        &.active {
            .page-link {
                color: $secondary;
            }
        }
    }
}

.nav-tabs {
    border-bottom: 1px solid $dark-gray;

    .nav-link {
        border: 0;
        border-bottom: 5px solid transparent;
        transition: all ease $transition-duration;
        padding-left: 0;
        padding-right: 0;
        margin: 0 1rem;

        &.active, &:hover {
            border-bottom-color: $secondary;
        }
    }
}

.tab-pane {
    &#home {
        .row {
            &:nth-child(odd) {
                .col-md-8 {
                    border-right: 10px solid $secondary;
                }
            }
            &:nth-child(even) {
                .col-md-4 {
                    @media only screen and (min-width: 768px){
                        order: 2;
                    }
                }
                .col-md-8 {
                    border-left: 10px solid $secondary;
                    @media only screen and (min-width: 768px){
                        order: 1;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 662px) {
    .fz-extra-large {
        font-size: 52px;
    }
}

@media only screen and (max-width: 992px) {
    iframe {
        height: 225px !important;
    }
}

.easy-access-menu {
    overflow: auto;
    padding-bottom: 0 !important;
    margin-bottom: 2rem;
    min-width: 100%;

    .col-12 {
        flex-wrap: nowrap !important;
        white-space: nowrap;
        margin: 0;
        max-width: none;
        flex: 1;
        width: auto;
    }

    /* width */
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #dee2e6; 
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #666; 
    }
}

.border-md-right {
    border-right: 1px solid #dee2e6;
    border-bottom: 0;
    @media only screen and (max-width: 768px) {
        border-right: 0;
        border-bottom: 1px solid #dee2e6;
    }
}

.search {
    position: relative;
    z-index: 6;

    #searchBox {
        position: relative;
        z-index: 5;
    }
}

#resultSearchContainer {
    position: absolute;
    padding-top: 40px;
    top: 0;
    z-index: 4;
    width: calc(100% - 30px);
    left: 15px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    h5 {
        display: none;
    }

    #boxSearchResultList {
        padding: 0 10px;

        &:hover {

            a {
                opacity: 0.5;
            }

            a:hover {
                opacity: 1;
            }
        }

        li {
            list-style-type: none;
            font-family: $font-family-barlow;
            padding-bottom: 10px;
            border-bottom: 1px solid #dee2e6;
            margin-top: 10px;

            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }

            a {
                text-decoration: none;
                display: flex;
                text-transform: uppercase;
            }

            figure {
                width: 30%;
                padding-right: 5px;

                img {
                    width: 100% !important;
                    height: auto !important;
                }
            }

            span {
                width: 70%;
            }

            h6 {
                font-size: 18px;
                display: block;
                margin-bottom: 0;
            }
            
            small {
                color: $secondary;
                font-size: 18px;
                display: block;
            }
        }
    }
}


.modal-cookies-preferences {
    position: fixed;
    z-index: 9999;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    display: none;
    max-width: 1000px;
    width: 100%;
    display: none;

    &.active {
        display: flex;
        flex-wrap: wrap;
    }

    form {
        .header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            background-color: #ebebeb;

            p {
                font-size: 18px;
                font-weight: bold;
                color: #3f4059;
            }

            .box-close {
                padding-right: 30px;

                .close {
                    width: 25px;
                    height: 25px;
                    cursor: pointer;
                    background: url(../images/btn-close-cookies-preferences.png) no-repeat center center;
                    display: block;
                }
            }
        }

        .content {
            display: flex;
            width: 100%;
            min-height: 340px;

            .tabs-nav {
                width: 40%;

                ul {
                    padding: 0px;
                    li {
                        display: flex;
                        color: #3f4059;
                        font-size: 14px;
                        border-bottom: 1px solid #e5e5e5;

                        .box-text {
                            cursor: pointer;
                            font-weight: bold;
                            width: 244px;
                            line-height: 1.2;
                            padding: 12px 20px;

                            &.active {
                                background-color: #00afef;
                                color: #ffffff;
                            }
                        }

                        .box-accepts {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 144px;
                            padding: 0 10px;

                            &.necessary-cookies {
                                justify-content: flex-end;
                                font-weight: bold;
                                color: #00afef;
                            }

                            .accept {
                                input {
                                    appearance: auto;
                                }
                            }
                        }

                        &:last-child {
                            border: none;
                            justify-content: center;
                            flex-direction: column;
                            padding: 5px 30px 30px 30px;

                            .message {
                                height: 25px;
                                color: #ffffff;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                &.show {
                                    color: #ff3a3a;
                                    font-weight: bold;
                                }
                            }

                            .box-buttons {
                                display: flex;
                                flex-direction: row;

                                button {
                                    border: none;
                                    width: 170px;
                                    font-weight: bold;
                                    font-size: 14px;
                                    padding: 10px 0;
                                    border-radius: 10px;
                                    cursor: pointer;

                                    &:first-child {
                                        margin-right: 4px;
                                    }

                                    &:last-child {
                                        margin-left: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .tabs {
                width: 60%;
                padding: 25px 40px;

                .tab {
                    display: none;
                    font-size: 14px;
                    color: #666666;

                    &.active {
                        display: block;
                    }

                    h3 {
                        margin-bottom: 20px;
                        color: #42435b;
                        font-weight: bold;
                    }

                    p {
                        font-size: 14px;
                        margin-bottom: 20px;
                        line-height: 1.3;
                        text-align: justify;
                    }
                }
            }
        }

        .footer {
            border-top: 1px solid #e5e5e5;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            padding: 20px 0;

            button {
                border: none;
                height: 40px;
                border-radius: 10px;
                background-color: #00afef;
                color: #ffffff;
                font-size: 14px;
                font-weight: bold;
                width: 220px;
                cursor: pointer;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        transform: none;
        top: 0;
        left: 0;
        height: 100vh;
        overflow: auto;

        form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;

            .header {
                padding: 15px;

                img {
                    display: none;
                }

                .box-close {
                    padding: 0;
                }
            }

            .content {
                height: 100%;
                min-height: unset;
                flex-direction: column;

                .tabs-nav {
                    width: 100%;

                    ul {
                        li {
                            .box-text {
                                width: 100%;
                            }

                            .box-accepts {
                                width: 200px;
                            }

                            &:last-child {
                                padding: 5px 20px 20px 20px;

                                .box-buttons {
                                    justify-content: center;
                                }
                            }
                        }
                    }
                }

                .tabs {
                    width: 100%;
                    padding: 0 20px;
                }
            }
        }
    }

    @media only screen and (max-width: 425px) {
        form {
            .header {
                p {
                    font-size: 15px;
                    line-height: 1.2;
                }
            }

            .content {
                .tabs-nav {

                    ul {
                        li {
                            &:last-child {
                                display: flex;
                                flex-direction: column;

                                .box-buttons {
                                    // flex-direction: column;

                                    button {
                                        width: 100%;
                                        margin-bottom: 10px;

                                        &:first-child {
                                            margin-right: 3px;
                                        }

                                        &:last-child {
                                            margin-left: 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .footer {
                padding: 20px;

                button {
                    width: 100%;
                }
            }
        }
    }
}

.cookies-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    &:before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0,0,0, .5);
        z-index: -1;
    }

    .container {
        background: #ebebeb;
        padding: 30px 0;
        width: 100%;

        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 1000px;
            width: 90%;
            margin: 0 auto;

            .text {
                color: #252642;
                max-width: 490px;

                h1 {
                    text-transform: unset;
                    color: #252642;
                    font-size: 24px;
                    font-weight: 700;
                    margin: 0 0 10px 0;
                    padding: 0;
                    text-align: unset;
                }

                p {
                    line-height: 16px;
                    font-size: 14px;
                }

                a {
                    color: #00afef;
                    text-decoration: underline;
                }
            }

            .links {
                form {
                    font-size: 14px;

                    a {
                        color: #00afef;
                        text-decoration: underline;
                    }

                    .cookies-accept {
                        width: 210px;
                        display: inline-block;
                        background: #00afef;
                        color: #fff;
                        text-align: center;
                        padding: 8px 0px;
                        border-radius: 8px;
                        font-weight: 700;
                        margin-left: 20px;
                        border: none;
                        cursor: pointer;
                    }
                }
            }

            @media only screen and (max-width: 768px) {
                .links {
                    form {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        a {
                            margin-bottom: 10px;
                        }

                        .cookies-accept {
                            margin-left: unset;
                        }
                    }
                }
            }

            @media only screen and (max-width: 425px) {
                flex-direction: column;

                .text {
                    margin-bottom: 20px;

                    h1 {
                        font-size: 22px;
                    }

                    p {
                        line-height: 15px;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

.vertical .carousel-inner {
    height: 100%;
  }
  
  .carousel.vertical .item {
    -webkit-transition: 0.6s ease-in-out top;
       -moz-transition: 0.6s ease-in-out top;
        -ms-transition: 0.6s ease-in-out top;
         -o-transition: 0.6s ease-in-out top;
            transition: 0.6s ease-in-out top;
  }
  
  .carousel.vertical .active {
    top: 0;
  }
  
  .carousel.vertical .next {
    top: 100%;
  }
  
  .carousel.vertical .prev {
    top: -100%;
  }
  
  .carousel.vertical .next.left,
  .carousel.vertical .prev.right {
    top: 0;
  }
  
  .carousel.vertical .active.left {
    top: -100%;
  }
  
  .carousel.vertical .active.right {
    top: 100%;
  }
  
  .carousel.vertical .item {
      left: 0;
  }

  .background-cut{
    width: 39%;
    background: url(../images/A_POWERTECH.jpeg) no-repeat right top;
    background-size: cover;
    clip-path: polygon(0 0,  55% 100%, 100% 100%, 100% 0);
  }
  .container-textapwt{
      width: 61%;
  }
  @media only screen and (max-width: 991px) {
    .container-apowertech{
        flex-direction: column;
    }
    .background-cut{
        width: 100%;
        height: 400px;
        clip-path: none;
    }
    .container-textapwt{
        width: 100%;
    }

  }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #444 !important;
}

.where-to-find-btn {
    margin-top: 35px;
}

.embed-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    width: 100%;
    height: 0;
    margin-bottom: 20px;

    iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
}

.quadros-add {
    display: flex;
    flex-wrap: wrap;
    .block-link {
        min-height: 185px;
        width: 200px;
        margin: 10px;

        img {
            width: 200px;
            height: 200px;
            object-fit: cover;
        }

        p {
            font-size: 18px;
            color: #fff;
            background: #252642;
            padding: 10px;
        }

        @media (max-width: 768px){
            width: 180px;
            img {
                width: 180px;
                height: 180px;
            }
        }

        @media (max-width: 525px){
            width: 100%;
            img {
                width: 100%;
            }
        }
    }
}

@media (max-width: 992px){
    .col-sm-3 {
        width: 100% ;
        max-width: none;
        flex:none ;
    }
    .col-sm-9 {
        width: 100% ;
        max-width: none;
        flex:none ;
    }
}

.delete-button{
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
}

.flatpickr-month{
    height: 40px !important;
}

.products .product:last-of-type hr{
    display: none !important;
}

.modal-profile-title{
    display: inline-block;
    color: #999999;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    word-wrap: break-word
}

.modal-profile-text{
    margin-top: 25px;
    display: inline-block;
    color: #15153B;
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 27px;
    word-wrap: break-word;
    @media screen and (max-width: 500px){
        font-size: 16px;
        line-height: 18px;
    }
}

.modal-profile-button{
    width: 90%;
    border-radius: 50px !important;
    &:focus{
        box-shadow: none !important;
    }
}

@media screen and (min-width: 992px){
    .warning-field{
        height: 45px;
    }
    
    .label-product-input{
        padding-top: 15px;
    }
}

@media (max-width: 1000px){
    #video-player div{
        iframe{
            height: 100% !important;
        }
    }
}